//statistic.js
import request from '@/utils/request'
import qs from 'qs'

// 订单统计数据
export function statistic (id,year,month) {
  let data = {
    id,year,month
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/fwsorderstatistics',
    method: 'post',
    data
  })
}
