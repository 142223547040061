<template>
  <div>
    <div class="statistic_title">订单统计</div>
    <div class="statistic_content">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-date-picker
            @change="order()"
            format="yyyy年"
            value-format="yyyy"
            type="year"
            v-model="formInline.year"
            placeholder="选择年"
            style="width: 120px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            @change="order()"
            format="MM月"
            value-format="MM"
            type="month"
            v-model="formInline.month"
            placeholder="选择月"
            style="width: 120px"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          ref="multipleTable"
          :header-cell-style="headeRowClass"
          tooltip-effect="dark"
          style="width: 100%"
          :data="tableData"
        >
          <el-table-column prop="chuli.sh" label="正在处理" sortable>
          </el-table-column>
          <el-table-column prop="bohui.sh" label="被驳回" sortable>
          </el-table-column>
          <el-table-column prop="wancheng.sh" label="已完成" sortable>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { statistic } from '@/API/statistic'
export default {
  data () {
    return {
      id: "", //服务商id
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      formInline: {
        year: "",
        month: ''
      },
      //表格数据
      tableData: []

    };
  },
  methods: {
    // 更改表头样式
    headeRowClass ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    //年和月份发生改变时访问后台数据
    order () {
      this.id = localStorage.getItem('FuWuId');
      statistic(this.id, this.formInline.year, this.formInline.month, this.currentPage, this.pagesize).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },

  },
  mounted () {
    this.order()
  }
};

</script>

<style>
.statistic_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #141414;
  border-bottom: 1px solid #e6e6e6;
}

.statistic_content {
  padding: 10px 0;
  box-sizing: border-box;
}
</style>